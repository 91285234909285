import React, { useEffect, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Seo from '../components/seo';
import ScrollbarCursorStyles from '../components/ScrollbarCursorStyles';

import MOVIES from '../data/movies.js';
import posterFallback from '../images/posters/fallback.jpg';

import gifRacerTrashMovies from '../images/movies/racertrashmovies.gif';
import gifNew from '../images/movies/new.gif';
import gifBackground from '../images/movies/background3.gif';
import gifNewMoviesEveryMonth from '../images/movies/newmovieseverymonth.gif';
import gifThankyou from '../images/movies/thankyou.gif';
import gifPalmtree from '../images/movies/palmtree.gif';
import gifSmooch from '../images/movies/smooch.gif';
import gifFilmReel from '../images/movies/filmreel.gif';
import gifFilmPancakes from '../images/movies/pancakes.gif';

const GlobalStyles = createGlobalStyle`
    body {
        margin: .5em;
        overflow-x: none;
        overflow-y: scroll;
        background: url(${gifBackground});
        font: 15px serif;
    }

    img {
      max-width: 100%;
    }
`;

const StyledMovieCreditList = styled.ul`
  list-style: none;
  display: block;
  margin: 0 0 0 -12px;
  padding: 0;
  overflow: hidden;

  li {
    margin: 0;
    padding: 0 0 0 4px;
    display: inline-block;
    white-space: nowrap;

    &::before {
      content: '• ';
    }
  }

  li:nth-child(2)::before {
    content: '';
  }
`;

const MovieCreditList = ({ title, isTitleItalic, children, ...rest }) => (
  <div style={{ overflow: 'hidden', marginTop: 4 }}>
    <StyledMovieCreditList {...rest}>
      <li>
        <strong style={isTitleItalic ? { 'font-style': 'italic' } : null}>{title}:</strong>
      </li>
      {children}
    </StyledMovieCreditList>
  </div>
);

const Movie = ({ image = posterFallback, title, date, lists, ...rest }) => {
  const isNew = 'isNew' in rest ? rest.isNew : date === MOVIES[0].date;
  return (
    <div {...rest}>
      <div>
        {image === posterFallback ? (
          <img src={image} alt={title} />
        ) : (
          <a href={image} target="_blank" rel="noreferrer">
            <img src={image} alt={title} />
          </a>
        )}
      </div>
      <div>
        <h3 style={{ margin: 0 }}>
          {isNew && (
            <img
              src={gifNew}
              alt="New!"
              style={{ verticalAlign: 'middle', marginRight: 4 }}
            />
          )}
          {title}
        </h3>
        {lists.map(({ title, isTitleItalic = false, items }) => (
          <MovieCreditList key={title} title={title} isTitleItalic={isTitleItalic}>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </MovieCreditList>
        ))}
      </div>
    </div>
  );
};
const StyledMovie = styled(Movie)`
  margin-bottom: 20px;

  > div:first-child img {
    width: 140px;
  }

  @media only screen and (min-width: 440px) {
    display: flex;

    > div:first-child img {
      display: block;
      margin-right: 20px;
      max-width: none;
    }
  }
`;

const DateSeparator = ({ children, ...rest }) => (
  <div {...rest}>
    <div>{children}</div>
    <span></span>
  </div>
);
const StyledDateSeparator = styled(DateSeparator)`
  display: flex;
  border-bottom: 3px solid #9b72cf;
  margin: 30px 0 10px;

  &:first-child {
    margin-top: 10px;
  }

  > div {
    width: 120px;
    background-color: #9b72cf;
    color: white;
    padding: 6px 4px 2px;
    text-align: center;
    font: bold 12px/1 Verdana, Arial, sans-serif;
    text-transform: capitalize;
  }

  > span {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent #9b72cf;
  }
`;

const MovieList = () => (
  <div style={{}}>
    {MOVIES.map((movie, index) => {
      const isNewMonth = MOVIES?.[index - 1]?.date !== movie.date;
      return (
        <React.Fragment key={movie.title}>
          {isNewMonth && (
            <StyledDateSeparator>{movie.date}</StyledDateSeparator>
          )}
          <StyledMovie {...movie} />
        </React.Fragment>
      );
    })}
  </div>
);

const NewMoviesEveryMonth = styled.div`
  height: 26px;
  background: url(${gifNewMoviesEveryMonth});
  background-size: 315px;
  animation: 5s infinite marquee linear;
  @keyframes marquee {
    from {
      background-position: 0 0;
    }
    from {
      background-position: 315px 0;
    }
  }
`;

const GeocitiesMoviesPage = () => {
  const handleClick = useCallback(() => {
    window.parent.postMessage('geocities:click');
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <>
      <ScrollbarCursorStyles />
      <GlobalStyles />
      <Seo title="Our Movies" />

      <h1 style={{ textAlign: 'center' }}>
        <img src={gifRacerTrashMovies} alt="Racer Trash Movies" />
      </h1>

      <NewMoviesEveryMonth />
      <br />

      <MovieList />

      <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 20 }}>
        <img src={gifThankyou} alt="" />
        <br />
        <img src={gifPalmtree} alt="" />
        <img src={gifSmooch} alt="" />
        <img src={gifFilmReel} alt="" height={100} />
        <img src={gifFilmPancakes} alt="" />
      </div>
    </>
  );
};

export default GeocitiesMoviesPage;
