import posterAbbadook from '../images/posters/abbadook.jpg';
import posterBeachHouse from '../images/posters/beachhouse.jpg';
import posterBlairWitch from '../images/posters/blairWitch.jpg';
import posterBlueKush from '../images/posters/bluekush.jpg';
import posterBoardsOfKaneda from '../images/posters/boardsOfKaneda.jpg';
import posterBrainCandy from '../images/posters/braincandy.jpg';
import posterCandman from '../images/posters/candman.jpg';
import posterClueless from '../images/posters/clueless.jpg'
import posterCthd from '../images/posters/cthd.jpg';
import posterDracalien from '../images/posters/dracalien.jpg';
import posterDrVibes from '../images/posters/drVibes.jpg';
import posterDubmanji from '../images/posters/dubmanji.jpg';
import posterDune from '../images/posters/dune.jpg';
import posterH4ck3rs from '../images/posters/h4ck3rs.jpg';
import posterHeatwave from '../images/posters/heatwave.jpg';
import posterId4 from '../images/posters/id4.jpg';
import posterItsBandTime from '../images/posters/itsbandtime.jpg';
import posterJazzAss from '../images/posters/jazzass.jpg';
import posterMasterOfHorror from '../images/posters/masterOfHorror.jpg';
import posterMmxxml from '../images/posters/mmxxml.jpg';
import posterProgSuperman from '../images/posters/progSuperman.jpg';
import posterRj from '../images/posters/rj.jpg';
import posterSpeedvapor from '../images/posters/speedvapor.jpg';
import posterSpeedVaporV2 from '../images/posters/speedVaporV2.jpg';
import posterSpiceWave from '../images/posters/spicewave.jpg';
import posterStarTrek420 from '../images/posters/starTrek420.jpg';
import posterTwoWaveBlacktop from '../images/posters/twowaveblacktop.jpg';
import posterVapormario from '../images/posters/vapormario.jpg';
import posterVertihwg from '../images/posters/vertihwg.jpg';
import posterVws from '../images/posters/vws.jpg';
import posterWavingLife from '../images/posters/wavinglife.jpg';
import posterYgn from '../images/posters/ygn.jpg';

const speedVapor = {
  title: 'speed vapor',
  date: 'may 2020',
  image: posterSpeedvapor,
  lists: [
    {
      title: 'edited by',
      items: [
        `alex jacobs*`,
        `ariel gardner*`,
        'jake robinson',
        'robby massey',
        'josh sasson',
        'david seger',
        'austin stock',
        "sean o'brien*",
        `ted marsden*`,
        'danny jelinek',
        `jason gudasz*`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    {
      title: 'captained by',
      items: ['alex jacobs'],
    },
  ],
};

const heatwav = {
  title: 'heatwave',
  date: 'june 2020',
  image: posterHeatwave,
  lists: [
    {
      title: `edited by`,
      items: [
        'jordan michael blake',
        `ariel gardner*`,
        'jason gudasz',
        `kyle reiter*`,
        'danielle billeaux',
        'ted marsden',
        'paul isakson',
        'patrick meraz',
        'austin stock',
        'josh sasson',
        'robby massey',
        'freddie wong',
        'erni walker',
        "sean o'brien*",
        `alex jacobs*`,
        'ben pluimer',
        'david seger',
        'danny jelinek',
        'jake robinson',
        'jess lane',
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const ID4 = {
  title: 'id4.mp4',
  date: 'july 2020',
  image: posterId4,
  lists: [
    {
      title: `edited by`,
      items: [
        `jess lane`,
        `sean o'brien`,
        `ben pluimer`,
        `jason gudasz*`,
        `robby massey`,
        `harrison atkins`,
        `d'arby rose†`,
        `paul isakson`,
        `grimy ghost!`,
        `alex jacobs†`,
        `ted marsden`,
        `danielle billeaux`,
        `austin stock`,
        `ariel gardner†`,
        `casey donahue`,
        `jen ruiz`,
        `zac becker`,
        `josh sasson`,
        `danny jelinek`,
        `freddie wong`,
        `ellie pritts`,
        `david seger`,
      ],
    },
    {
      title: `* with sounds by`,
      items: ['dave valdez'],
    },
    {
      title: `† with colors by`,
      items: ['ellie pritts'],
    },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const vapormario = {
  title: 'vapor mario',
  date: 'august 2020',
  image: posterVapormario,
  lists: [
    {
      title: `edited by`,
      items: [
        `nolan fabricius`,
        `ted marsden`,
        `david seger`,
        `jake robinson`,
        `ellie pritts`,
        `robby massey`,
        `bobby mccoy`,
        `sean o'brien`,
        `jordan michael blake`,
        `casey donahue`,
        `grimy ghost!`,
        `jess lane`,
        `austin stock`,
        `danielle billeaux`,
        `josh sasson`,
        `ben pluimer`,
        `harrison atkins`,
        `ariel gardner*`,
        `ryan perez*`,
        `alex jacobs*`,
        `paul isakson`,
        `danny jelinek`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    { title: `add'l sounds by`, items: ['dave valdez'] },
    { title: 'thank you to', items: [`smbmovie.com for add'l footage`] },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const romeojulietugh = {
  title: 'r+j',
  date: 'september 2020',
  image: posterRj,
  lists: [
    {
      title: `edited by`,
      items: [
        `alex jacobs*`,
        `ariel gardner*`,
        `chloë brett`,
        `jess lane`,
        `d'arby rose*`,
        `paul isakson`,
        `josh sasson*`,
        `bobby mccoy`,
        `casey donahue`,
        `ben pluimer`,
        `harrison atkins`,
        `robby massey`,
        `ted marsden`,
        `david seger`,
        `marisa demarini`,
        `ellie pritts`,
        `grimy ghost!`,
        `claire levinson`,
        `danielle billeaux`,
        `jake robinson`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const draculol = {
  title: 'draculol',
  date: 'october 2020',
  image: posterDracalien,
  lists: [
    {
      title: `edited by`,
      items: [
        `josh sasson*`,
        `claire levinson`,
        `paul isakson`,
        `harrison atkins*`,
        `robby massey`,
        `nolan fabricius`,
        `d'arby rose & marisa demarini*`,
        `austin stock`,
        `paul bonanno`,
        `cory garcía*`,
        `ariel gardner*`,
        `chloë brett`,
        `casey donahue`,
        `alex jacobs*`,
        `ryan perez`,
        `danielle billeaux`,
        `jason gudasz`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    { title: 'poster by', items: ['justin baillargeon'] },
    { title: `produced by`, items: ['dana swanson'] },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const alienx5 = {
  title: 'alien alien alien alien alien',
  date: 'october 2020',
  image: posterDracalien,
  lists: [
    {
      title: `edited by`,
      items: [
        `danielle billeaux`,
        `sean o'brien*`,
        `grimy ghost!`,
        `chloë brett`,
        `david seger`,
        `jess lane`,
        `aaron moles`,
        `ted marsden`,
        `d'arby rose`,
        `alex jacobs*`,
        `jake robinson`,
        `robby massey`,
        `ben pluimer`,
        `marisa demarini`,
        `ariel gardner*`,
        `ellie pritts`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    {
      title: `† with vfx by`,
      items: ['paul cornett'],
    },
    { title: 'poster by', items: ['justin baillargeon'] },
    { title: `produced by`, items: ['dana swanson'] },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

// FIXME: ellie color
const dubmanji = {
  title: 'dubmanji',
  date: 'november 2020',
  image: posterDubmanji,
  lists: [
    {
      title: `edited by`,
      items: [
        `ariel gardner`,
        `josh sasson`,
        `sean o'brien`,
        `ted marsden`,
        `claire levinson`,
        `alex jacobs`,
        `jake robinson`,
        `ellie pritts`,
      ],
    },
    { title: `add'l colors by`, items: ['ellie pritts'] },
    { title: `captained by`, items: ['ariel gardner'] },
  ],
};

const wavinglife = {
  title: 'waving life',
  date: 'november 2020',
  image: posterWavingLife,
  lists: [
    {
      title: `edited by`,
      items: [
        `paul bonanno`, // ("overture")`,
        `marisa demarini`, // ("dream is destiny // anchors aweigh")`,
        `ariel gardner`, // ("life lessons")`,
        `alex jacobs`, // ("alienation")`,
        `robby massey`, // ("death and reality")`,
        `paul cornett`, // ("provocation")`,
        `ben pluimer`, // ("free will")`,
        `ted marsden`, // ("aging paradox")`,
        `harrison atkins`, // ("what's the story?")`,
        `david seger`, // ("dreams")`,
        `jordan michael blake`, // ("the holy moment")`,
        `casey donahue`, // ("society is fraud")`,
        `sean o'brien`, // ("dreamers")`,
        `jake robinson`, // ("ants")`,
        `jess lane`, // ("we are the authors // meet yourself")`,
        `ellie pritts`, // ("trapped in a dream")`,
        `ariel gardner & marisa demarini & ellie pritts`, // ("wake up!"),
        `paul isakson`, // (sleepwave)`,
      ],
    },
    { title: `produced by`, items: ['dana swanson'] },
    { title: `captained by`, items: ['ariel gardner'] },
  ],
};

const drVibes = {
  title: 'dr. vibes',
  date: 'november 2020',
  image: posterDrVibes,
  lists: [
    {
      title: `edited by`,
      items: [
        `alex jacobs*`,
        `robby massey`,
        `ariel gardner*`,
        `jake robinson`,
        `adam wingard`,
        `chloë brett`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const cthd = {
  title: 'cthd',
  date: 'december 2020',
  image: posterCthd,
  lists: [
    {
      title: `edited by`,
      items: [
        `ted marsden`,
        `david seger`,
        `josh sasson`,
        `cristina isabel rivera`,
        `grimy ghost!`,
        `adam wingard`,
        `casey donahue`,
        `robby massey`,
        `kate freund`,
        `austin stock`,
        `jess lane`,
        `marisa demarini`,
        `d'arby rose`,
        `kati skelton`,
        `claire levinson`,
        `danielle billeaux`,
        `alex jacobs*`,
        `chloë brett`,
        `jake robinson†`,
        `paul bonanno`,
        `ariel gardner*‡`,
        `danny jelinek`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    {
      title: `† with vfx by`,
      items: ['marisa demarini'],
    },
    {
      title: `‡ with vfx by`,
      items: ['david jackson'],
    },
    { title: `produced by`, items: ['dana swanson'] },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const vws = {
  title: 'vibes wide shut',
  date: 'december 2020',
  image: posterVws,
  lists: [
    {
      title: `edited by`,
      items: [
        `marisa demarini`,
        `ariel gardner*`,
        `ellie pritts`,
        `chloë brett`,
        `jake robinson`,
        `kyle reiter`,
        `robby massey`,
        `harrison atkins`,
        `claire levinson`,
        `paul bonanno`,
        `d'arby rose`,
        `alex jacobs`,
        `ben pluimer`,
        `jess lane`,
        `paul bonanno (sleepwave)`,
      ],
    },
    {
      title: `* with vfx by`,
      items: ['david jackson'],
    },
    { title: `add'l colors by`, items: ['ellie pritts'] },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const h4ck3rs = {
  title: 'h4ck3rs',
  date: 'january 2021',
  image: posterH4ck3rs,
  lists: [
    {
      title: `edited by`,
      items: [
        'freddie wong',
        `chloë brett`,
        `harrison atkins`,
        `alex jacobs*`,
        `d'arby rose`,
        `ted marsden`,
        `adam wingard`,
        `sean o'brien`,
        `jake robinson`,
        `ellie pritts`,
        `kati skelton`,
        `paul bonanno`,
        `nolan fabricius`,
        `ben pluimer`,
        `danielle billeaux`,
        `claire levinson`,
        `paul isakson`,
        `ariel gardner*†`,
        `jess lane`,
        `robby massey`,
        `casey donahue`,
        `david seger`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    {
      title: `† with vfx by`,
      items: ['david jackson'],
    },
    { title: `produced by`, items: ['dana swanson'] },
    { title: `captained by`, items: ['alex jacobs'] },
  ],
};

const abbadook = {
  title: 'abbadook',
  date: 'january 2021',
  image: posterAbbadook,
  lists: [
    {
      title: `edited by`,
      items: [
        `josh sasson`,
        `ariel gardner`,
        `jess lane`,
        `grimy ghost!`,
        `jake robinson`,
        `claire levinson`,
        `alex jacobs`,
        `d'arby rose`,
      ],
    },
    { title: `add'l colors by`, items: ['ellie pritts'] },
    {
      title: `"Baba-loo"`,
      items: ['vox and lyrics by jess lane', 'bg vox danny pesses'],
    },
    { title: `captained by`, items: ['josh sasson'] },
  ],
};

const clueless = {
  title: '~~~clueless~~~',
  date: 'february 2021',
  image: posterClueless,
  lists: [
    {
      title: `edited by`,
      items: [
        `d'arby rose`,
        `richelle chen`,
        `marisa demarini`,
        `ariel gardner*`,
        `claire levinson`,
        `ted marsden†`,
        `jonah ray`,
        `harrison atkins*`,
        `grimy ghost!`,
        `brodie reed`,
        `chloë brett`,
        `josh sasson`,
        `bobby mccoy`,
        `robby massey`,
        `starline hodge`,
        `david seger`,
        `dri sommer`,
        `alex jacobs*`,
        `jess lane`,
      ],
    },
    {
      title: `* with colors by`,
      items: ['ellie pritts'],
    },
    {
      title: `† with vfx by`,
      items: ['marisa demarini'],
    },
    { title: `captained by`, items: ['marisa demarini'] },
  ],
};

// FIXME: ellie colors
const youvegotnails = {
  title: `you've got nails`,
  date: 'february 2021',
  image: posterYgn,
  lists: [
    {
      title: 'edited by',
      items: [
        `sean o'brien`,
        'milos mitrovic',
        'matthew wells',
        `ariel gardner*`,
        'jake robinson',
        'marisa demarini',
        'aaron moles',
        'casey donahue',
        'ted marsden',
        'david seger',
        'robby massey',
        'chloë brett',
        'alex jacobs',
        'ellie pritts',
      ],
    },
    {
      title: `* with vfx by`,
      items: ['david jackson'],
    },
    { title: `add'l colors by`, items: ['ellie pritts'] },
    { title: `captained by`, items: ["sean o'brien"] },
  ],
};

const vertihwg = {
  title: `vertihwg`,
  date: 'march 2021',
  image: posterVertihwg,
  lists: [
    {
      title: 'edited by',
      items: [
        'marisa demarini*',
        'ariel gardner†',
        'matthew wells',
        'jake robinson‡',
        "d'arby rose§¶",
        'david seger#**',
        'ryan perez',
        'paul bonanno',
        'ellie pritts',
        'robby massey',
        'danielle billeaux',
        'milos mitrovic',
        'josh sasson',
        'ted marsden',
        'alex jacobs††',
        'claire levinson',
        'ben pluimer',
        'kati skelton',
        'casey donahue',
        'kyle reiter',
        'brodie reed',
        'chloë brett',
        "sean o'brien",
      ],
    },
    { title: '* with vo by', items: ['dri sommer'] },
    { title: '† with vfx by', items: ['david jackson'] },
    { title: '‡ with vfx by', items: ['marisa demarini'] },
    { title: '§ with title by', items: ["sean o'brien"] },
    { title: '¶ with cam op by', items: ['gwen harlow'] },
    { title: '# with colors by', items: ['ellie pritts'] },
    { title: '** with vo by', items: ['jason gudasz'] },
    {
      title: '†† with vo by',
      items: [
        `eric jacobs`,
        `robby massey`,
        `jake robinson`,
        `d'arby rose`,
        `david seger`,
      ],
    },
    { title: 'captained by', items: ['ariel gardner'] },
  ],
};

const itsBandTime = {
  title: `it's band time`,
  date: 'march 2021',
  image: posterItsBandTime,
  lists: [
    {
      title: 'edited by',
      items: [
        'harrison atkins*',
        'kati skelton†',
        'nolan fabricius',
        'ariel gardner‡',
        'ellie pritts',
        'jason gudasz',
        'robby massey',
        "sean o'brien",
        'paul isakson',
        'ben pluimer',
        'austin stock',
        'jake robinson',
        'casey donahue',
        'alex jacobs§',
        'jess lane',
      ],
    },
    {
      title: '* with performance by',
      items: ['SPO'],
    },
    {
      title: '† with guitar solo by',
      items: ['daniel johnson'],
    },
    { title: '‡ with vfx by', items: ['ellie pritts'] },
    { title: '§ with guitar by', items: ["d'arby rose"] },
    { title: "add'l colors by", items: ['ellie pritts'] },
    { title: "add'l vfx by", items: ['marisa demarini', 'paul cornett'] },
    {
      title: 'captained by',
      items: ['robby massey'],
    },
  ],
};

const jazzAss = {
  title: `jazz ass`,
  date: 'april 2021',
  image: posterJazzAss,
  lists: [
    {
      title: 'edited by',
      items: [
        `jake robinson`,
        `jess lane`,
        `daniel johnson`,
        `ben pluimer`,
        `robby massey`,
        `harrison atkins`,
        `casey donahue`,
        `paul isakson`,
        `kati skelton`,
        `ellie pritts`,
        `ariel gardner`,
        `josh sasson`,
        `marisa demarini`,
        `ted marsden`,
        `sean o'brien`,
        `chloë brett`,
        `alex jacobs`,
      ],
    },
    {
      title: 'captained by',
      items: [`harrison atkins`],
    },
  ],
};

const beachHouse = {
  title: `beach house`,
  date: 'april 2021',
  image: posterBeachHouse,
  lists: [
    {
      title: 'edited by',
      items: [
        `alex jacobs`,
        `ellie pritts`,
        `sky kim`,
        `grimy ghost!*`,
        `richelle chen`,
        `josh sasson†‡`,
        `chloë brett`,
        `marisa demarini`,
        `robby massey`,
        `claire levinson`,
        `jess lane`,
        `jake robinson`,
        `paul bonanno`,
        `daniel johnson`,
        `d'arby rose`,
      ],
    },
    {
      title: '* with vo by',
      items: ['kati skelton'],
    },
    {
      title: '† with cat puppeteering by',
      items: ['claire levinson'],
    },
    { title: '‡ with special appearance by', items: ['scully'] },
    {
      title: 'captained by',
      items: ['alex jacobs'],
    },
  ],
};

const boardsOfKaneda = {
  title: 'boards of kaneda',
  date: 'may 2021',
  image: posterBoardsOfKaneda,
  lists: [
    {
      title: 'edited by',
      items: [
        `adam wingard`,
        `daniel johnson`,
        `claire levinson`,
        `jake robinson`,
        `kati skelton`,
        `paul bonnano`,
        `chloë brett`,
        `robby massey`,
        `alex jacobs`,
        `ted marsden`,
        `d'arby rose`,
        `harrison atkins`,
        `sean o'brien`,
        `david jackson*`,
      ],
    },
    {
      title: '* with vo by',
      items: ['the bok players'],
    },
    {
      title: 'with credits by',
      items: ['ted marsden'],
    },
    {
      title: 'captained by',
      items: ['ted marsden'],
    },
  ],
};

const speedVapor2021 = {
  title: 'speed vapor v2',
  date: 'may 2021',
  image: posterSpeedVaporV2,
  lists: [
    {
      title: 'edited by',
      items: [
        `paul bananno`,
        `casey donahue`,
        `jess lane`,
        `sky kim`,
        `ben pluimer`,
        `nolan fabricius`,
        `paul isakson`,
        `harrison atkins`,
        `adam wingard`,
        `grimy ghost!*`,
        `chloë brett`,
      ],
    },
    { title: '* with vfx by', items: [`paul bonanno`] },
    {
      title: 'captained by',
      items: [`marisa demarini`, `richelle chen`, `jd hartley`],
    },
  ],
};

const twoWaveBlacktop = {
  title: 'two-wave blacktop',
  date: 'may 2021',
  image: posterTwoWaveBlacktop,
  lists: [
    {
      title: 'edited by',
      items: [
        `alex jacobs*`,
        `ariel gardner*`,
        `jake robinson`,
        `danny jelinek`,
        `robby massey`,
        `josh sasson`,
        `david seger`,
        `austin stock`,
        `sean o'brien`,
        `ted marsden`,
      ],
    },
    {
      title: '* with colors by',
      items: ['ellie pritts'],
    },
    {
      title: 'captained by',
      items: ['alex jacobs'],
    },
  ],
};

const spiceWave = {
  title: 'spice wave',
  date: 'may 2021',
  image: posterSpiceWave,
  lists: [
    {
      title: 'edited by',
      items: [
        `claire levinson`,
        `lola isabel`,
        `d'arby rose`,
        `ellie pritts`,
        `hazel rose`,
        `jess lane`,
        `starline hodge`,
        `danielle billeaux*`,
        `nina reyes rosenberg†`,
        `sky kim‡`,
        `marisa demarini`,
        `richelle chen`,
        `chloë brett`,
      ],
    },
    { title: `* with special guest appearance`, items: [`paul isakson`] },
    { title: `† featuring`, items: [`mason j. smith`] },
    { title: `‡ with music by`, items: ['bill mcclintock'] },
    {
      title: `with special thanks`,
      items: [
        `robby massey`,
        'gwen harlow',
        'alex jacobs',
        'claire levinson',
        'josh sasson',
        'paul bonanno',
      ],
    },
    { title: `captained by`, items: [`d'arby rose`] },
  ],
};

const starTrek420 = {
  title: 'star trek IV-20: thc voyage home',
  date: 'june 2021',
  isNew: false,
  image: posterStarTrek420,
  lists: [
    {
      title: 'edited by',
      items: [
        `robby massey`,
        `d'arby rose`,
        `ted marsden`,
        `claire levinson`,
        `josh sasson`,
        `marisa demarini`,
        `chloë brett`,
        `alex jacobs`,
        `sean o'brien`,
        `dave seger`,
        `danielle billeaux`,
        `david jackson`,
        `casey donahue`,
        `grimy ghost!`,
        `paul bonanno`,
      ],
    },
    { title: `captained by`, items: [`josh sasson`] },
  ],
};

const progSuperman = {
  title: 'suprogman',
  date: 'june 2021',
  isNew: false,
  image: posterProgSuperman,
  lists: [
    {
      title: 'edited by',
      items: [
        `ariel gardner`,
        `jake robinson`,
        `richelle chen`,
        `ben pluimer`,
        `aaron moles`,
        `dave seger`,
        `josh sasson`,
        `claire levinson`,
        `sean o'brien`,
        `jordan michael blake`,
        `ted marsden`,
        `paul isakson`,
        `robby massey`,
        `harry atkins`,
        `daniel johnson`,
        `ellie pritts`,
        `alex jacobs`,
        `chloë brett`,
      ],
    },
    { title: `captained by`, items: [`ariel gardner`] },
  ],
};

const blairWitch = {
  title: 'blairwitch.prproj',
  date: 'june 2021',
  image: posterBlairWitch,
  lists: [
    {
      title: 'edited by',
      items: [
        `marisa demarini*`,
        `ariel gardner†`,
        `adam wingard`,
        `sky kim + neil davis`,
        `jake robinson`,
        `jerry yoo`,
        `chloë brett`,
        `ellie pritts`,
        `richelle chen`,
        `dave seger`,
        `casey donahue`,
        `harrison atkins`,
        `alex jacobs`,
        `claire levinson`,
        `matthew wells`,
        `robby massey`,
        `grimy ghost!‡`,
        `josh sasson‡`,
      ],
    },
    {
      title: '* with vo by',
      items: [`thia schuessler`, `gabriella ortega ricketts`],
    },
    { title: `† with analog vfx by`, items: ['ellie pritts'] },
    { title: `‡ with analog vfx by`, items: ['david jackson'] },
    { title: 'poster by', items: ['justin baillargeon'] },
    { title: `captained by`, items: [`grimy ghost!`] },
  ],
};

const masterOfHorror = {
  title: 'Master of Horror: Scar-a-Thon',
  date: 'june 2021',
  image: posterMasterOfHorror,
  lists: [
    { title: `Maximum OverVibe`, items: [`paul bonanno`], isTitleItalic: true },
    { title: `The Mangler`, items: [`ben plumier`], isTitleItalic: true },
    { title: `It`, items: [`claire levinson`], isTitleItalic: true },
    { title: `Christine/Sometimes They Come Back`, items: [`alex jacobs*`], isTitleItalic: true },
    { title: `Tommy Knockers`, items: [`robby massey`], isTitleItalic: true },
    { title: `Fire Starter`, items: [`ellie pritts`], isTitleItalic: true },
    { title: `Misery`, items: [`sky kim`], isTitleItalic: true },
    { title: `Cat’s Eye`, items: [`deanna rooney`], isTitleItalic: true },
    { title: `I’m a Creep(show) 2`, items: [`jonah ray`], isTitleItalic: true },
    { title: `The Stand`, items: [`aaron moles`], isTitleItalic: true },
    { title: `The Rage: Carrie 2`, items: [`d’arby rose`], isTitleItalic: true },
    { title: `Silver Bullet`, items: [`paul bonanno`], isTitleItalic: true },
    { title: `Pet Cemetery`, items: [`lola isabel`], isTitleItalic: true },
    { title: `Shawshank Redemption`, items: [`jeffrey max`], isTitleItalic: true },
    { title: `Thinner`, items: [`ryan perez`], isTitleItalic: true },
    { title: `Carrie`, items: [`kati skelton`], isTitleItalic: true },

    {
      title: '* with strobes by',
      items: ['jake robinson'],
    },
  ],
};

const mmxxml = {
  title: 'magic mike.xxml',
  date: 'july 2021',
  image: posterMmxxml,
  lists: [
    {
      title: 'edited by',
      items: [
        `claire levinson`,
        `dave seger`,
        `jess lane`,
        `ariel gardner`,
        `neil davis`,
        `richelle chen`,
        `milos mitrovic`,
        `casey donahue`,
        `cristina isabel rivera`,
        `chloë brett`,
        `starline hodge`,
        `sky kim`,
        `lola isabel`,
        `alex jacobs`,
        `paul bonanno`,
        `daniel johnson`,
        `kati skelton`,
        `jake robinson`,
        `robby massey`,
      ],
    },
    { title: 'poster by', items: ['justin baillargeon'] },
    { title: `captained by`, items: [`chloë brett`] },
  ],
};

const candman = {
  title: 'can•d•man',
  date: 'september 2021',
  isNew: false,
  image: posterCandman,
  lists: [
    {
      title: 'edited by',
      items: [
        `ellie pritts`,
        `casey donahue`,
        `robby massey`,
        `marisa demarini`,
        `chloë brett`,
        `sean o'brien`,
        `ted marsden*`,
        `harrison atkins`,
        `paul bonnano`,
        `alex jacobs`,
        `milos mitrovic`,
        `dave seger`,
        `neil davis`,
        `austin stocks†`,
        `lola isabel`,
      ],
    },
    { title: '* starring', items: ['demorge brown'] },
    {
      title: `† with vfx by`,
      items: ['paul cornett'],
    },
    { title: 'poster by', items: ['justin baillargeon'] },
    { title: 'captained by', items: ['ted marsden'] },
  ],
};

const brainCandy = {
  title: 'brain candy',
  date: 'september 2021',
  isNew: false,
  image: posterBrainCandy,
  lists: [
    {
      title: 'edited by',
      items: [
        `sky kim`,
        `dave seger`,
        `robby massey`,
        `danny jelinek*`,
        `chloë brett`,
        `claire levinson`,
        `ariel gardner†`,
        `paul bonanno`,
        `jess lane`,
        `lola isabel`,
        `milos mitrovic`,
        `david jackson`,
        `alex jacobs`,
      ],
    },
    { title: '* with vo by', items: ['matt ginsberg'] },
    {
      title: `† with colors and art by`,
      items: ['ellie pritts'],
    },
    { title: 'captained by', items: ['chloë brett'] },
  ],
};

const dune = {
  title: `dune '84 '21`,
  date: 'september 2021',
  isNew: false,
  image: posterDune,
  lists: [
    {
      title: 'edited by',
      items: [
        `nolan fabricius`,
        `cristina isabel rivera`,
        `robby massey`,
        `chloë brett`,
        `josh sasson`,
        `alex jacobs`,
        `paui bonanno`,
        `sean o'brien`,
        `daniel johnson*`,
        `ariel gardner & david jackson`,
        `richelle chen`,
        `ted marsden`,
        `lola isabel`,
        `kati skelton`,
        `milos mitrovic`,
        `jordan michael blake`,
        `starline hodge`,
        `jess lane`,
        `ben pluimer`,
        `sky kim`,
        `casey donahue`,
        `jake robinson`,
        `claire levinson`,
        `d'arby rose†`,
      ],
    },
    {
      title: '* with "HELLPLAGUE" font by',
      items: ['ilja moltšanov'],
    },
    {
      title: '† with analog glitching by',
      items: ['cris sanchez'],
    },
    {
      title: 'captained by',
      items: ['alex jacobs'],
    },
  ],
};

const blueKush = {
  title: `blue kush`,
  date: 'september 2021',
  image: posterBlueKush,
  lists: [
    {
      title: 'edited by',
      items: [
        `starline hodge`,
        `richelle chen`,
        `alex jacobs`,
        `lola isabel`,
        `neil davis`,
        `milos mitrovic`,
        `chloë brett`,
        `matthew wells`,
        `derek reining`,
        `marisa demarini*`,
        `ted marsden`,
        `david seger`,
        `ariel gardner & ellie pritts`,
      ],
    },
    {
      title: '* with film review by',
      items: ['chloë brett'],
    },
    {
      title: `captained by`,
      items: ['marisa demarini'],
    },
  ],
};

const MOVIES = [
  blueKush,
  dune,
  brainCandy,
  candman,
  mmxxml,
  masterOfHorror,
  blairWitch,
  starTrek420,
  progSuperman,
  boardsOfKaneda,
  spiceWave,
  speedVapor2021,
  twoWaveBlacktop,
  jazzAss,
  beachHouse,
  vertihwg,
  itsBandTime,
  clueless,
  youvegotnails,
  h4ck3rs,
  abbadook,
  cthd,
  vws,
  wavinglife,
  dubmanji,
  drVibes,
  draculol,
  alienx5,
  romeojulietugh,
  vapormario,
  ID4,
  heatwav,
  speedVapor,
];

export default MOVIES;
