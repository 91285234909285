import glitchCursor from '../images/glitch-cursor.png';
import { createGlobalStyle } from 'styled-components';

const ScrollbarCursorStyles = createGlobalStyle`
body {
    cursor: url(${glitchCursor}) 4 0, auto;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAIAAAD91JpzAAAAFElEQVQIW2M4fPz0////GYAYyAIASnoKpV3w4kgAAAAASUVORK5CYII=");
  image-rendering: pixelated;
}
::-webkit-scrollbar-track:active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAIAAAD91JpzAAAAEElEQVQIW2No6+pjgAAgCwAWogM9VKrgGQAAAABJRU5ErkJggg==");
}

::-webkit-scrollbar-thumb {
  border-top: 1px solid #c3c7cb;
  border-left: 1px solid #c3c7cb;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  box-shadow: inset 1px 1px 0 0 white, inset -1px -1px 0 0 #868a8e;
  width: 16px;
  height: 16px;
  background-color: #c3c7cb;
  z-index: 1;
}

::-webkit-scrollbar-corner {
  background-color: #c3c7cb;
}

::-webkit-resizer {
  width: 16px;
  height: 16px;
  background-color: #c3c7cb;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAN0lEQVR4Ae3MgQUAMBRDwU5fFF05lb/CARTBw2Ulof0DxPtcwp3hNuEYnjbcEW4TjuFpwx3h9gMWGgZ2Y/PT2gAAAABJRU5ErkJggg==");
  background-position: bottom right;
  background-repeat: no-repeat;
  image-rendering: pixelated;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-button {
  border-top: 1px solid #c3c7cb;
  border-left: 1px solid #c3c7cb;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  box-shadow: inset 1px 1px 0 0 white, inset -1px -1px 0 0 #868a8e;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #c3c7cb;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-position: center center;
}
::-webkit-scrollbar-button:active,
::-webkit-scrollbar-button:active {
  background-position: 2px 2px;
}
::-webkit-scrollbar-button:horizontal:decrement,
::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAHklEQVQY02NgoBT8xyX8H5fwf1zCpOjAYwceV1EEAAO2D/HsQ4vsAAAAAElFTkSuQmCC");
}
::-webkit-scrollbar-button:horizontal:increment,
::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAHUlEQVQY02NgIB/8xy3xH7fEf9wS/0nUQZqrKAYAK44P8ZRmzLQAAAAASUVORK5CYII=");
}
::-webkit-scrollbar-button:vertical:decrement,
::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGklEQVR4AWMYxuA/SYphmETFhDX9x4mHGQAAcL4P8dQiMq8AAAAASUVORK5CYII=");
}
::-webkit-scrollbar-button:vertical:increment,
::-webkit-scrollbar-button:vertical:increment {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAF0lEQVQY02NgoBf4jwJxSOHQhcNAOgMAWWAP8Rv2U3UAAAAASUVORK5CYII=");
}
::-webkit-scrollbar-button:horizontal:increment:start,
::-webkit-scrollbar-button:horizontal:increment:start {
  display: none;
}
::-webkit-scrollbar-button:horizontal:decrement:end,
::-webkit-scrollbar-button:horizontal:decrement:end {
  display: none;
}
::-webkit-scrollbar-button:vertical:increment:start,
::-webkit-scrollbar-button:vertical:increment:start {
  display: none;
}
::-webkit-scrollbar-button:vertical:decrement:end,
::-webkit-scrollbar-button:vertical:decrement:end {
  display: none;
}
::-webkit-scrollbar-button:active,
::-webkit-scrollbar-button:active {
  border-top: 1px solid #868a8e;
  border-left: 1px solid #868a8e;
  border-bottom: 1px solid #868a8e;
  border-right: 1px solid #868a8e;
  box-shadow: none;
}
`;

export default ScrollbarCursorStyles;
